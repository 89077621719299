import { productTitle, isProductSchema } from '~/utils/helpers';
import { META_TITLE, META_DESC } from './constants';
import { capitalize } from 'vue';

const metaTitle = (
	data: object,
	storeId: number,
	storeType?: string,
): string => {
	// @ts-ignore
	const data_ = data!._object.data;
	const title = data_?.seo_meta_title;
	if (storeType == 'OCTANE') {
		if (title) {
			if (storeId in title) {
				return title[storeId];
			}
		}
	}
	return defaultMetaTitle(data_);
};

const metaDesc = (
	data: object,
	storeId: number,
	storeType?: string,
	storeName?: string,
): string => {
	// @ts-ignore
	const data_ = data!._object.data;
	const description = data_?.seo_meta_description;
	if (storeType == 'OCTANE') {
		if (description) {
			if (storeId in description) {
				return description[storeId];
			}
		}
		return data_?.description
			? defaultMetaDescription(data_)
			: `Discover the latest trends with ${storeName}, where style meets innovation. Explore cutting-edge fashion and timeless elegance in every collection`;
	}
	return data_?.description
		? `${defaultMetaDescription(data_)}`
		: META_DESC.LAAM;
};

const defaultMetaTitle = (data: object): string => {
	// @ts-ignore
	if (data.node_l1 && isProductSchema(data)) {
		const prodTitle = productTitle(data, true);
		// @ts-ignore
		return capitalize(prodTitle.title);
		// @ts-ignore
	} else return `${data.title} - ${data.partner}`;
};

const defaultMetaDescription = (data: object): string => {
	// @ts-ignore
	const prodDesc = productTitle(data, true);
	// @ts-ignore
	return capitalize(prodDesc.description.replace(/\.$/, ''));
};

const dropMetaTitle = (
	data: object,
	storeId: number,
	storeType?: string,
	storeName?: string,
): string | null => {
	if (!data) return null;
	// @ts-ignore
	const title = data?.seo_meta_title;

	if (storeType == 'OCTANE') {
		if (title) {
			if (storeId in title) {
				return title[storeId];
			}
		}
		// @ts-ignore
		return `${data?.title} | ${storeName}`;
	}
	return (
		// @ts-ignore
		data?.meta_title || `${data?.title} | ${storeName} | ${META_TITLE.LAAM}`
	);
};

const dropMetaDesc = (
	data: object,
	storeId: number,
	storeType?: string,
	storeName?: string,
): string | null => {
	if (!data) return null;
	// @ts-ignore
	const description = data?.seo_meta_description;

	if (storeType == 'OCTANE') {
		if (description) {
			if (storeId in description) {
				return description[storeId];
			}
		}
		// @ts-ignore
		return data?.description
			? // @ts-ignore
				`${data?.description}`
			: `Discover the latest trends with ${storeName}, where style meets innovation. Explore cutting-edge fashion and timeless elegance in every collection.`;
	}
	// @ts-ignore
	return data?.meta_description
		? // @ts-ignore
			`${data?.meta_description}`
		: META_DESC.LAAM;
};

export {
	metaTitle,
	metaDesc,
	defaultMetaTitle,
	defaultMetaDescription,
	dropMetaDesc,
	dropMetaTitle,
};
